import React, { Component } from "react" 
import { Helmet } from "react-helmet"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Header from "./header"
import Sidebar from "./sidebar"
import Content from "./content"
import Img from "gatsby-image"

import KontaktMap from "./leafMap"
import 'leaflet/dist/leaflet.css'

class Layout extends Component {

    state = {
        sidebarHide: true,
        sidebarClass: 'sidebar',
    }

    handleToggle = () => {
        this.setState((prevState) => {
            if(prevState.sidebarHide){
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar sidebar-show-mobile',
                }
            } else {
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar',
                }
            }
            
        })
    }

    render() {
        
        let pageImage
        let featuredImage
        let kontaktMap
        
        if(this.props.featuredImage === "null"){
            pageImage = <Img className="inline-image" fluid={this.props.pageImage} />
        } else if(this.props.pageImage === "null"){
            featuredImage = <Img fluid={this.props.featuredImage} />
        } else {
            pageImage = <Img className="inline-image" fluid={this.props.pageImage} />
            featuredImage = <Img fluid={this.props.featuredImage} />
        }
        if(typeof window !== "undefined" && this.props.pageTitle === "Anfahrt"){
            kontaktMap = <KontaktMap />
        }

        return (
            <div className="main" style={{ height: '100%' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Ines Puettcher Pflege</title>
                </Helmet>
                <Header clickFunction={this.handleToggle} menuState={this.state.sidebarHide} /> 
                <div className="wrapper">
                    <Sidebar sidebarClass={this.state.sidebarClass} />
                    <Container fluid className="mainCol">
                        <Row>
                            <Col>
                                <div className="page-image">{pageImage}</div>
                                <Content pageContent={this.props.pageContent} />
                                {kontaktMap}
                                <div className="featured-image">{featuredImage}</div>
                            </Col>
                        </Row>
                    </Container>
                    
                </div>
                
            </div>
        )
    }
}

export default Layout