import React, { Component } from 'react'
import L from 'leaflet'
import { Map, TileLayer, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

class KontaktMap extends Component {
  state = {
    lat: 52.513,
    lng: 13.522,
    zoom: 13,
  }

  render() {
    
    const position = [this.state.lat, this.state.lng]

    const newIcon = new L.Icon({
      iconUrl: require("../images/marker-icon.png"),
      iconRetinaUrl: require("../images/marker-icon.png"),
      iconAnchor: [5, 55],
      popupAnchor: [10, -44],
      iconSize: [25, 41],
      shadowUrl: require("../images/marker-shadow.png"),
      shadowSize: [41, 41],
      shadowAnchor: [5, 55]
    })

    return (
      <Map center={position} zoom={this.state.zoom}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={newIcon}>
        </Marker>
      </Map>
    )
  }
}

export default KontaktMap
